import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from '../components';

class GeneralTemplate extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        page,
      },
      location,
    } = this.props;
    const {
      uid: pageSlug,
      data: pageData,
    } = page;
    const {
      pageTitle,
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <SliceZone
          siteMetadata={siteMetadata}
          pageSlug={pageSlug}
          pageTitle={pageTitle}
          allSlices={body}
          location={location}
        />
      </Layout>
    );
  }
}

export default GeneralTemplate;

export const pageQuery = graphql`
  query GeneralTemplateQuery($uid: String!) {
    site {
      siteMetadata {
        blogSlug,
      }
    },
    page: prismicGeneralTemplate(uid: { eq: $uid }) {
      uid
      data {
        pageTitle: page_name {
          text
        }
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicGeneralTemplateBodyPageHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              description {
                text
              }
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              theme
            }
          }
          ... on PrismicGeneralTemplateBodyBannerHero {
            id
            sliceType: slice_type
            primary {
              theme
              title {
                text
              }
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              body {
                html
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyTextBanner {
            id
            sliceType: slice_type
            primary {
              theme
              title {
                text
              }
              body {
                html
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyIconsBanner {
            id
            sliceType: slice_type
            primary {
              theme
              title {
                text
              }
            }
            items {
              iconLabel: icon_label {
                text
              }
              iconLink: icon_link {
                target
                slug
                linkType: link_type
                url
              }
              iconImage: icon_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
              }
            }
          }
          ... on PrismicGeneralTemplateBodyNewsletterSignup {
            id
            sliceType: slice_type
            primary {
              theme
              title {
                text
              }
              body {
                html
              }
              successMessageTitle: success_message_title {
                text
              }
              successMessageBody: success_message_body {
                html
              }
              successMessageButtonLabel: success_message_button_label {
                text
              }
              successMessageButtonLink: success_message_button_link {
                target
                slug
                linkType: link_type
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyBreadcrumbs {
            id
            sliceType: slice_type
            primary {
              theme
            }
            items {
              pageLabel: page_label {
                text
              }
              pageLink: page_link {
                target
                slug
                linkType: link_type
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyIllustratedActions {
            id
            sliceType: slice_type
            items {
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              illustrationStyle: illustration_style
              theme
              hoverDescription: hover_description {
                text
              }
              actionLabel: action_label {
                text
              }
              actionLink: action_link {
                target
                slug
                linkType: link_type
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyBigImageTextBlock {
            sliceType: slice_type
            id
            primary {
              theme
              subtitle {
                text
              }
              photo {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
            }
          }
          ... on PrismicGeneralTemplateBodyVideoTextBlock {
            id
            sliceType: slice_type
            primary {
              theme
              headline {
                text
              }
              subtitle {
                text
              }
              description {
                html
              }
              theme
              videoProvider: video_provider
              videoUrl: video_url {
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyDocumentCarousel {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              theme
            }
            items {
              tileTheme: tile_theme
              documentFile: document_file {
                url
              }
              documentTitle: document_title {
                text
              }
              year {
                text
              }
            }
          }
          ... on PrismicGeneralTemplateBodyLatestPostsBlock {
            sliceType: slice_type
            id
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
            }
          }
          ... on PrismicGeneralTemplateBodyTextHero {
            id
            sliceType: slice_type
            primary {
              theme
              headline {
                text
                html
              }
              description {
                text
                html
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyCallToActionTiles {
            id
            sliceType: slice_type
            primary {
              itemsPerRow: items_per_row
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              title {
                text
                html
              }
              description {
                text
                html
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyResourceTiles {
            id
            sliceType: slice_type
            primary {
              theme
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              title {
                text
                html
              }
              description {
                text
                html
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicGeneralTemplateBodyImageBlock {
            id
            sliceType: slice_type
            primary {
              theme
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
            }
          }
          ... on PrismicGeneralTemplateBodyVideoBlock {
            id
            sliceType: slice_type
            primary {
              theme
              videoProvider: video_provider
              videoUrl: video_url {
                url
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;
